
import pagesConfig from "../config/config"
import AttendanceSettingTop from "../features/attendance/AttendanceSettingTop";


const APP_URL = {
  login: "/login",
  help: "/andescloud-help/",
  accountRegister: "/public/account-register",
  forgotPassword: "/update-password",
  manager: {
    detailStorage: "/detail-storage",
    gabage: "/detail-storage/gabage"
  },
  portal: {
    portal: "/portal",
    store: "/store",
    storeDocuments: "/store/documents",
    storeDetail: "/store/detail",
    appMenu: "/portal/app-menu",
    appDetail: "/portal/app/detail",
    suisyouShinsei: "/portal/app/detail/suisyou-shinsei",
    suisyouHonkan: "/portal/app/detail/suisyou-honkan",
    support: "/portal/support",
    supportConfim: "/portal/support/confirm",
    newsList: "/portal/news/list",
    newsDetail: "/portal/news/detail",

    company: "/portal/company-setting",
    locationMst: "/portal//location-mst",
    companyregno: "/potal/company/regno",
    companyregnoRegister: "/potal/company/regno/register",
    department: "/portal/company/department",
    loginUser: "/portal/company/login-user",
    loginUserRegister: "/portal/company/login-user/register",
    employee: "/portal/company/employee",
    employeeRegister: "/portal/company/employee/register",
    customer: "/portal/company/customer",
    customerRegister: "/portal/company/customer/register",
    customerEmployee: "/portal/company/customer/employee",
    customerEmployeeRegister: "/portal/company/customer/employee/register",
    customerRegno: "/portal/company/customer/regno",
    customerRegnoRegister: "/portal/company/customer/regno/register",
    permission: "/portal/company/permission",
    setting: "/portal/company/setting",
    passwordUpdate: "/portal/company/passwordUpdate",
    // ツールダウンロード
    tools:{
      tools: "/portal/tools",
      andesDriveSyncTool : "/portal/tools/AndesDriveSyncTool"
    },
    sharedStorage: "/shared-storage",
    bulletin: "/bulletins",
    documents: "/documents"
  },
  sncAdmin: {
    companyAdmin: "/snc-admin/company",
    companyRegister: "/snc-admin/company/register",
    sncAdmin: "/snc-admin/user",
    sncRegister: "/snc-admin/user/register",
    systemAdmin: "/snc-admin/system",
    systemRegister: "/snc-admin/system/register",
    storeAdmin: "/snc-admin/store",
    storeRegister: "/snc-admin/store/register",
    template: "/snc-admin/system/template",
    localConfSetting: "/snc-admin/localConfSetting",
    newsManage: "/snc-admin/news-manage",
    newsManageRegister: "/snc-admin/news-manage/register",
    newsManageAndes: "/snc-admin/news-manageAndes",
    newsManageRegisterAndes: "/snc-admin/news-manage/registerAndes"
  },
  andesBackup: {
    andesBackup: "/andes-backup"
  },
  quotation: {
    list: "/quotation",
    register: "/quotation/register",
    edit: "/quotation/:id/edit",
    confirm: "/quotation/:id/confirm",
    duplicate: "/quotation/duplicate"
  },
  invoice: {
    invoice: "/invoice",
    register: "/invoice/register",
    edit: "/invoice/:id/edit",
    detail: "/invoice/:id/detail",
    duplicate: "/invoice/duplicate"
  },
  dairyReport: {
    dairyReport: "/dairy-report",
    register: "/dairy-report/register",
    detail: "/dairy-report/detail",
    history: "/dairy-report/history",
    templates: "/dairy-report/templates",
    templateRegister: "/dairy-report/template/register",
    listItems: "/dairy-report/list-items",
    listItemsRegister: "/dairy-report/list-items/register",
    laborer: "/dairy-report/laborer",
    laborerStart: "/dairy-report/laborer/start",
    laborerRegister: "/dairy-report/laborer/register",
    bukkenSearch: "/dairy-report/laborer/search",
    employeeSearch: "/dairy-report/laborer/employee_search",
    workKind: "/dairy-report/laborer/work_kind",
    weather: "/dairy-report/laborer/weather",
    DairyReportSetting: "/dairy-report/setting",
    TimeLineSetting: "/timeline-setting",
  },

  attendance:{
    stamp                     : "/attendance",
    daily                     : "/attendance/daily",
    edit                      : "/attendance/edit",


    confirm                   : "/attendance/confirm",
    detail                    : "/attendance/confirm/detail",
    confirmedit               : "/attendance/confirm/detail/edit",

    attendanceSettingTop      : "/attendance/setting",
    attendanceSetting         : "/attendance/setting/setting1",

    typeSetting               : "/attendance/setting/setting2",
    typeSettingRegister       : "/attendance/setting/setting2/register",

    patternSetting            : "/attendance/setting/setting3",
    patternSettingRegister    : "/attendance/setting/setting3/register",
    patternSettingCalendar    : "/attendance/setting/setting3/carendar",

    departmentSetting         : "/attendance/setting/setting4",
    departmentSettingRegister : "/attendance/setting/setting4/register",


    employeeSetting           : "/attendance/setting/setting5",
    employeeSettingRegister   : "/attendance/setting/setting5/register",

  },
  localConfigManage: {
    localConfigManage: "/local-config-manage", // 地域設定 ログインユーザーによる振り分け
    localConfiglist: "/local-config-manage/location-list", // 地域設定一覧
    localConfigAdd: "/snc-admin/local-config-manage/location-add", // 地域設定追加
    localConfigAdminEdit: "/snc-admin/local-config-manage/admin/location-edit", // SNCユーザー　地域設定編集
    localConfAdminUpinfo: "/snc-admin/local-config-manage/admin/update-info", // SNCユーザー　更新情報
    localConfCompanyList: "/snc-admin/local-config-manage/admin/campany", // SNCユーザー　会社地域設定
    localConfCompanySetting: "/snc-admin/local-config-manage/admin/campany/setting" // SNCユーザー　会社地域設定　会社のページ
    // localConfcompanyAdd: "/local-config-manage/admin/campany/setting/location-add",     // SNCユーザー　会社地域設定　会社のページ
  },
  bukken: {
    // NOTE bukken/list → bukken
    bukkenList: "/bukken",
    bukkenSettingList: "/bukken/setting-list",
    bukkenSearchPatternEdit: "/bukken/search-pattern-edit",
    bukkenRelatedInfo: "/bukken/:id/related-info",
    bukkenRegister: "/bukken/register",
    bukkenEdit: "/bukken/:id/edit",
    bukkenDetail: "/bukken/:id/detail",

    bukkenClassMst: "/bukken-class-mst",
    bukkenClassMstRegister: "/bukken-class-mst/register",
    bukkenClassMstEdit: "/bukken-class-mst/:id/edit",

    bukkenTabMst: "/bukken-tab-mst",
    bukkenTabMstRegister: "/bukken-tab-mst/register",
    bukkenTabMstEdit: "/bukken-tab-mst/:id/edit",

    bukkenItemMst: "/bukken-item-mst",
    bukkenItemMstRegister: "/bukken-item-mst/register",
    bukkenItemMstEdit: "/bukken-item-mst/:id/edit",

    bukkenTemplateMst: "/bukken-template-mst",
    bukkenTemplateMstRegister: "/bukken-template-mst/register",
    bukkenTemplateMstEdit: "/bukken-template-mst/:id/edit",

    bukkenDrawingMst: "/bukken-drawing-mst",
    // bukkenTemplateMstRegister: "/bukken-template-mst/register",
    // bukkenTemplateMstEdit: "/bukken-template-mst/:id/edit",

    constTypeMst: "/const-type-mst",
    constTypeMstRegister: "/const-type-mst/register",
    constTypeMstEdit: "/const-type-mst/:id/edit",

    locationMst: "/location-mst",

    bukkenFiles: "/bukken/:id/files",
    bukkenImages: "/bukken/:id/images",
    bukkenDrawing: "/bukken/:id/drawing",

    bukkenSetting: "/bukken-setting"
  },

  // Photobook
  photobook: {
    id: "/photobook", // not real route just id to active sidebar
    detailPhotobook: "/photobook/:bukkenId/detail-photobook/:photoId",
    photobooks: "/photobook/:id/photobooks",
    createPhotobook: "/photobook/:bukkenId/create-photobook",
    editPhotobook: "/photobook/:bukkenId/edit-photobook/:photoId",
    duplicatePhotobook: "/photobook/:bukkenId/duplicate-photobook"
  },
  
  /* Document-Repository */
  
  document: {
    list: '/documents',
    register: '/documents/register',
    detail: '/documents/:documentId/detail',
    edit: '/documents/:documentId/edit'
  },
  
  // bulletin: {
  //   list: '/bulletins/:categoryId',
  //   register: '/bulletins/:categoryId/register',
  //   detail: '/bulletins/:categoryId/:bulletinId/detail',
  //   edit: '/bulletins/:categoryId/:bulletinId/edit',
  //   duplicate: '/bulletins/:categoryId/duplicate'
  // }
  bulletin: {
    list: '/bulletins',
    register: '/bulletins/register',
    detail: '/bulletins/:bulletinId/detail',
    edit: '/bulletins/:bulletinId/edit',
    duplicate: '/bulletins/duplicate'
  }
  
};

export let LONG_NAME = (url) => {
  return `/app${url}`;
};

const server_url_base = "https://www.systemsnakashima.andescloud.jp/";
export const SERVER_URL = {
  files: {
    download_raw: `${server_url_base}/download/file`
  }
};

export default APP_URL;
