import { createSlice } from "@reduxjs/toolkit";

export const stateDocument = createSlice({
  name: "stateDocument",
  initialState: {
    pageActive: 1,
    folderActive: {
      folder_name: JSON.parse(localStorage.getItem("folderActiveDocument"))?.folder_name || null,
      folder_uuid: JSON.parse(localStorage.getItem("folderActiveDocument"))?.folder_uuid || null
    },
    files: [],
    menus: localStorage.getItem("menus") ? [...JSON.parse(localStorage.getItem("menus"))] : [],
    bodySearch: {
      folder_uuid: [
        {
          keyword: JSON.parse(localStorage.getItem("folderActiveDocument"))?.folderUuid || null,
          condition: "LIKE"
        }
      ]
    }
  },
  reducers: {
    setPageActive: (state, action) => {
      state.pageActive = action.payload;
    },
    setFolderActive: (state, action) => {
      state.folderActive = action.payload;
      localStorage.setItem("folderActiveDocument", JSON.stringify(action.payload));
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setMenus: (state, action) => {
      state.menus = action.payload;
      localStorage.setItem("menus", JSON.stringify(action.payload));
    },
    setBodySearch: (state, action) => {
      state.bodySearch = action.payload;
    }
  }
});

export const { setPageActive, setFolderActive, setFiles, setMenus, setBodySearch } = stateDocument.actions;

export default stateDocument.reducer;
