import { createSlice } from "@reduxjs/toolkit";

export const stateBulletin = createSlice({
  name: "stateBulletin",
  initialState: {
    pageActive: 1,
    folderActive: {
      folderName: JSON.parse(localStorage.getItem("folderActive"))?.folderName || null,
      folderUuid: JSON.parse(localStorage.getItem("folderActive"))?.folderUuid || null
    },
    files: [],
    bodySearch: {
      folder_uuid: [
        {
          keyword: JSON.parse(localStorage.getItem("folderActive"))?.folderUuid || null,
          condition: "LIKE"
        }
      ]
    }
  },
  reducers: {
    setPageActive: (state, action) => {
      state.pageActive = action.payload;
    },
    setFolderActive: (state, action) => {
      localStorage.setItem("folderActive", JSON.stringify(action.payload));
      state.folderActive = action.payload;
      state.bodySearch.folder_uuid[0].keyword = JSON.parse(localStorage.getItem("folderActive"))?.folderUuid
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setBodySearch: (state, action) => {
      state.bodySearch = action.payload
    }
  }
});

export const {
  setPageActive,
  setFolderActive,
  setFiles,
  setBodySearch
} = stateBulletin.actions;

export default stateBulletin.reducer;
