/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import APP_URL, { LONG_NAME } from "../../../declaration/URL";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import PageCard from "../../../components/Cards/PageCard";
import { useEffect } from "react";
import { initSelectUser } from "../../common/attendanceSlice/stateDailyAttendance";
import { useDispatch } from "react-redux";

function AttendanceSettingTop() {
  const dispatch = useDispatch();
  const crumbs = [{ to: LONG_NAME(APP_URL.bukken.bukkenSetting), title: "設定" }];

  useEffect(()=>{
    dispatch(initSelectUser());
  },[]);

  return (
    <div className="page_main_area">
      <BreadCrumbs
        crumbs={[
          { to: "", title: "設定" },
        ]}
      />
      <PageCard>
        <div className="card bg-white p-4 overflow-hidden">
          <div className="setting_page_link">
            <Link to={LONG_NAME(APP_URL.attendance.attendanceSetting)}>勤怠設定</Link>
          </div>
          <div className="setting_page_link">
            <Link to={LONG_NAME(APP_URL.attendance.typeSetting)}>勤怠区分</Link>
          </div>
          <div className="setting_page_link">
            <Link to={LONG_NAME(APP_URL.attendance.patternSetting)}>勤務パターン</Link>
          </div>
          <div className="setting_page_link">
            <Link to={LONG_NAME(APP_URL.attendance.departmentSetting)}>部署パターン設定</Link>
          </div>
          <div className="setting_page_link">
            <Link to={LONG_NAME(APP_URL.attendance.employeeSetting)}>社員パターン設定</Link>
          </div>
        </div>
      </PageCard>
    </div>
  );
}

export default AttendanceSettingTop;
