import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useMatch } from 'react-router-dom'
import { setPageActive } from '../features/common/documents-repository/bulletins'
import APP_URL from '../declaration/URL'
import { setFolderActive } from '../features/common/documents-repository/bulletins'

const useResetBulletin = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const matchBulletinDetail = useMatch("/app/bulletins/:bulletinId/detail");
  const matchBulletinEdit = useMatch("/app/bulletins/:bulletinId/edit");
  const matchBulletinList = useMatch("/app/bulletins")
  
  useEffect(() => {
    if (!matchBulletinDetail && !matchBulletinEdit && !matchBulletinList) {
      dispatch(setPageActive(1))
    }
    
    if (!pathname.startsWith(`/app${APP_URL.bulletin.list}`)) {
      localStorage.removeItem('folderActive')
      dispatch(
        setFolderActive({
          folderName: null,
          folderUuid: null
        })
      )
    }
  }, [dispatch, matchBulletinEdit, matchBulletinDetail, matchBulletinList, pathname])
}

export default useResetBulletin