import { createSlice } from "@reduxjs/toolkit";
import { itemBukkenConstruct, itemBukkenOrder, joinMember } from "../../../utils/bukken/helpers/structureValueDefault";

export const stateModalSlice = createSlice({
  name: "stateModal",
  initialState: {
    customerJoinMember: {
      customer_uuid: null,
      company_name: null
    },
    companyUuidSelected: {
      company_uuid: null,
      company_name: null
    },
    joinMembers: [{ ...joinMember }],

    bukkenItem: {}, // relatedModal
    departmentItem: {},
    customerUuid: null,
    ownManager: {},
    customerEmployeeData: {},
    constTypeUuid: null,
    bukkenConstruct: [{ ...itemBukkenConstruct }],
    bukkenOrder: [{ ...itemBukkenOrder }],
    itemMasterReference: {},
    indexItemMasterReference: null,
    customerInfo: {},
    employeeInfo: {},
    listTaxKinds: []
  },
  reducers: {
    setJoinMembers: (state, action) => {
      if (!action.payload?.length) {
        state.joinMembers = [joinMember];
        return;
      }
      state.joinMembers = action.payload;
    },
    setLocationUsed: (state, action) => {
      state.location_used = action.payload;
    },
    setBukkenItem: (state, action) => {
      state.bukkenItem = action.payload;
    },
    setDepartmentItem: (state, action) => {
      state.departmentItem = action.payload;
    },
    setCustomerUuid: (state, action) => {
      state.customerUuid = action.payload;
    },
    setCustomerEmployeeName: (state, action) => {
      state.customerEmployeeData = action.payload;
    },
    setConstTypeUuid: (state, action) => {
      state.constTypeUuid = action.payload;
    },
    setOwnManager: (state, action) => {
      state.ownManager = { ...action.payload };
    },
    setBukkenConstruct: (state, action) => {
      if (!action.payload?.length) {
        state.bukkenConstruct = [itemBukkenConstruct];
        return;
      }
      const newPayload = [...action.payload];
      for (let i = 0; i < newPayload?.length; i++) {
        if (!newPayload[i]?.bukken_construct_manager?.length) {
          newPayload[i].bukken_construct_manager = itemBukkenConstruct.bukken_construct_manager;
        }
      }
      state.bukkenConstruct = newPayload;
    },
    setBukkenOrder: (state, action) => {
      if (!action.payload?.length) {
        state.bukkenOrder = [itemBukkenOrder];
        return;
      }
      const newPayload = [...action.payload];
      for (let i = 0; i < newPayload?.length; i++) {
        if (!newPayload[i]?.bukken_orderer_manager?.length) {
          newPayload[i].bukken_orderer_manager = itemBukkenOrder.bukken_orderer_manager;
        }
      }
      state.bukkenOrder = newPayload;
    },

    setCustomerJoinMember: (state, action) => {
      state.customerJoinMember = action.payload;
    },

    setDefaultAll: (state) => {
      state.departmentItem = {};
      state.ownManager = {};
      state.bukkenItem = {};
      state.bukkenOrder = [{ ...itemBukkenOrder }];
      state.bukkenConstruct = [{ ...itemBukkenConstruct }];
      state.joinMembers = [{ ...joinMember }];
    },

    setItemMasterReference: (state, action) => {
      state.itemMasterReference = { ...action.payload };
    },

    setIndexItemMasterReference: (state, action) => {
      state.indexItemMasterReference = action.payload;
    },

    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },

    setEmployeeInfo: (state, action) => {
      state.employeeInfo = action.payload;
    },

    setListTaxKinds: (state, action) => {
      state.listTaxKinds = action.payload;
    }
  }
});

export const {
  setJoinMembers,
  setLocationUsed,
  setBukkenItem,
  setDepartmentItem,
  setCustomerUuid,
  setCustomerEmployeeName,
  setConstTypeUuid,
  setOwnManager,
  setBukkenConstruct,
  setBukkenOrder,
  setDefaultAll,
  setCustomerJoinMember,
  setItemMasterReference,
  setIndexItemMasterReference,
  setCustomerInfo,
  setEmployeeInfo,
  setListTaxKinds
} = stateModalSlice.actions;

export default stateModalSlice.reducer;
