import React, { lazy, useEffect } from "react"
import { useSelector } from "react-redux"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { themeChange } from "theme-change"
import "./App.css"
import checkAuth from "./app/auth"
import initializeApp from "./app/init"
import pagesConfig from "./config/config"
import PDFViewer from "./features/photobook/detailPhotobook/components/PDFViewer"
import useReSetConditionInvoice from "./hook/useResetConditionInvoice"
import useReSetConditionQuotation from "./hook/useResetConditionQuotation"
import useResetPagePhotobook from "./hook/useResetPagePhotobook"
import useSetDefaultStateBukkenSearch from "./hook/useSetDefaultStateBukkenSearch"
import TokenService from "./services/tokenService"
import { ScrollToTop } from "./utils/scrolltotop"
import useResetBulletin from "./hook/useResetBulletin"

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const DetailStorage = lazy(() => import("./pages/protected/DetailStorage"));
const RecycleBin = lazy(() => import("./pages/protected/RecycleBin"));
const ForgotPassword = lazy(() => import("./pages/protected/ForgotPassword"));


// Initializing different libraries
initializeApp();


// Check for login and initialize axios
// const token = checkAuth();
const App = () => {
  const navigate = useNavigate()
  useSetDefaultStateBukkenSearch()
  useReSetConditionQuotation()
  useReSetConditionInvoice()
  useResetPagePhotobook()
  useResetBulletin()
  useEffect(() => {
    themeChange(false);
  }, []);


  const {accessToken, userType} = useSelector((state) => state.stateLoginInfo);
  const token = accessToken ?? TokenService.getLocalAccessToken();
  checkAuth(accessToken);
  const type = userType ?? localStorage.getItem("userType");

  const paramMap = {};
  const url = window.location.href;
  const urlParams = url.split("?");
  if(urlParams.length > 1) {
    const params = urlParams[1].split("&");
    for(let param of params) {
      const kv = param.split("=");
      paramMap[kv[0]] = kv[1];
    }
  }

  /**
   * デフォルトのアクセス先を取得する
   */
  const getDefaultPage = () => {
    if(token) {
      if(type == pagesConfig.userType.snc_user) {
        return pagesConfig.sncPortal.path;
      }
      return pagesConfig.portal.path;
    }

    if(paramMap["token"]) {
      return `${pagesConfig.forgot.path}?token=${paramMap['token']}`;
    }
    return pagesConfig.login.path;
  };

  /**
   * アクセス先ページを取得する
   * @param {*} expect
   * @returns
   */
  const getLoginPage = (paramMap) => {
    if(paramMap["token"]) {
      return <Navigate to={`${pagesConfig.forgot.path}?token=${paramMap['token']}`} replace />;
    }

    return <Login />;
  };

  /**
   * アクセス先ページを取得する
   * @param {*} expect
   * @returns
   */
  const getAccessPage = (paramMap, token) => {
    if(paramMap["token"]) {
      return <Navigate to={`${pagesConfig.forgot.path}?token=${paramMap['token']}`} replace />;
    }
    return <Layout />;
  };

  // redirect to detail bukken from cad application
  const handleRedirectToDetailBukkenFromCad = () => {
    const pathName = window.location.pathname;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isReirect = params.get("redirectFromCad");
    if (!token && isReirect) {
      window.urlRedirectFromCad = pathName + search
      navigate(pagesConfig.login.path)
      return;
    }
  };

  useEffect(() => {
    handleRedirectToDetailBukkenFromCad();
  }, [])

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path={pagesConfig.login.path}
          element={getLoginPage(paramMap)}
        />
        <Route path={pagesConfig.forgot.path} element={<ForgotPassword />} />
        {/* 管理者設定から遷移させる
        <Route
          path={`${pagesConfig.companyInfo.path}`}
          element={token ? <CompanyInfo /> : <Login />}
        />
        */}
        <Route
          path={`${pagesConfig.storageDetail.path}`}
          element={token ? <DetailStorage /> : <Login />}
        />
        <Route
          path={`${pagesConfig.recycleBin.path}/*`}
          element={token ? <RecycleBin /> : <Login />}
        />
        
        <Route path="/pdf-viewer" element={<PDFViewer />} />

        {/* SNC権限のみアクセス可*/}
        {/*
        {type != pagesConfig.userType.snc_user && <Route path="/app/snc-admin/*" element={<Navigate to={pagesConfig.portal.path} replace />} />}
      */}
        {/* Place new routes over this */}
        <Route path="/app/*" element={getAccessPage(paramMap, token)} />
        {/* <Route path="/app/*" render={(props) => getAccessPage(paramMap, token)} /> */}

        <Route
          path="*"
          element={<Navigate to={getDefaultPage()} replace />}
        />
      </Routes>
    </>
  );
}
export default App;
